import React, { useEffect, useState } from "react";
import {
  cambiarCantidad,
  cambiarPrecio,
  actualizarPrecio as reduxActualizarPrecio,
  eliminarItem,
  updateDescripcionPersonalizadaDetalle,
} from "../../../Redux/Actions/presupuestoActions";
import {
  TableBody,
  TableCell,
  TableRow,
  Backdrop,
  TextField,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FormFeedback, FormInput, FormSelect } from "shards-react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import MaskedInput from "react-text-mask";
import {
  actualizarPrecio,
  createPedidoDesdeRepuesto,
  observacionArticulo,
} from "../../../requests/urls";
import request from "../../../requests/request";
import LocationIcon from "../../../components/ait-reusable/LocationIcon";
import ModalObservacionarticulo from "../../Proveedores/Catalogo/Modales/ModalObservacionArticulo";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
import { useLocation } from "react-router-dom";
import ModalPedido from "./Components/ModalPedido";
import {
  handleKeyPress,
  handlePaste,
} from "../../../components/ait-reusable/InputValidations";
import { PERMISOS } from "../../../constantes/permisos";
import { tienePermisos } from "../../../utils/tienePermisos";
import {
  handleMostrarCodigoArticulo,
  handleMostrarDescripcionArticulo,
} from "./Components/Utils";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fontSizeOptions: {
    fontSize: 18,
  },
}));

const ItemList = ({
  articulo,
  index,
  isVenta,
  idOrden,
  handleOpenObservaciones,
  handleOpenModalPedido,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { empleado, perfil } = useSelector((state) => state.loginReducer);
  const { detalles, descuento, medioPago, cliente } = useSelector(
    (store) => store.presupuesto,
  );

  const [listaDePrecios, setListaDePrecios] = useState([]);
  const [selectedListaDePrecios, setSelectedListaDePrecios] = useState(null);
  const [descripcionPersonalizada, setDescripcionPersonalizada] = useState("");

  const isPresupuesto = location.pathname.includes("presupuesto");

  useEffect(() => {
    if (articulo.lista_precios) {
      setListaDePrecios(articulo.lista_precios);
      let default_lp;
      if (articulo.idListaPrecio) {
        default_lp = articulo.lista_precios.some(
          (lp) => lp.id === articulo.idListaPrecio,
        )
          ? articulo.idListaPrecio
          : null;
      } else {
        let findLista = articulo.lista_precios.find((lp) => lp.es_default);
        if (findLista) {
          default_lp = findLista.id;
        }
      }
      setSelectedListaDePrecios(default_lp);
    }
  }, [articulo]);

  const handleShowCodigo = (articulo) => {
    if (configGeneral.codigo_comprobante === "proveedor") {
      return articulo.codProveedor
        ? articulo.codProveedor.toUpperCase() + " " + "-" + " "
        : "";
    } else if (configGeneral.codigo_comprobante === "original") {
      return articulo.codOriginal
        ? articulo.codOriginal.toUpperCase() + " " + "-" + " "
        : "";
    } else if (configGeneral.codigo_comprobante === "auxiliar") {
      return articulo.codAuxiliar
        ? articulo.codAuxiliar.toUpperCase() + " " + "-" + " "
        : "";
    }
  };

  const changeListaPrecio = (event) => {
    const { value } = event.target;
    setSelectedListaDePrecios(value);
    obtenerPrecioArticulo(value);
  };

  const obtenerPrecioArticulo = async (lista_de_precios) => {
    const response = await request({
      method: "GET",
      url: actualizarPrecio,
      params: {
        idArticuloProveedor: articulo.idRepuestoProveedor,
        listaPrecios: lista_de_precios,
        fraccionar_precio: Number(true),
      },
    });
    dispatch(
      reduxActualizarPrecio(
        detalles,
        index,
        response.data.data,
        configGeneral.redondeo_precio,
        cliente,
        descuento,
        medioPago,
        lista_de_precios,
      ),
    );
  };

  // Mostrar componentes del articulo
  const handleRenderDescripcion = (articulo) => {
    const puedeEditarDescripcion = tienePermisos(empleado, PERMISOS.VENTAS);

    if (puedeEditarDescripcion) {
      return (
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          value={descripcionPersonalizada}
          defaultValue={descripcionPersonalizada}
          onChange={(e) => {
            setDescripcionPersonalizada(e.target.value);
            dispatch(
              updateDescripcionPersonalizadaDetalle(
                detalles,
                index,
                e.target.value,
              ),
            );
          }}
          InputProps={{
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip="Al modificar esta descripción sólo impactará en los comprobantes, la descripción original del artículo no cambiará."
                position="top"
              />
            ),
          }}
        />
      );
    }
    return (
      <>
        <span className={classes.fontSizeOptions}>
          {handleMostrarCodigoArticulo(articulo, configGeneral)}
          {handleMostrarDescripcionArticulo(articulo)}
        </span>

        {!isPresupuesto &&
        articulo.idRepuestoProveedor &&
        articulo.observaciones.length > 0 ? (
          <p style={{ fontSize: "0.8rem", opacity: "0.5" }}>
            {articulo.observaciones[0].substr(0, 40).trim() +
              (articulo.observaciones[0].length > 40 ? "..." : "")}
          </p>
        ) : null}
      </>
    );
  };
  // Mostrar componentes del articulo

  const handleSetDescripcionPersonalizada = () => {
    if (!articulo) return setDescripcionPersonalizada("");

    if (articulo.descripcionPersonalizada) {
      return setDescripcionPersonalizada(articulo.descripcionPersonalizada);
    }

    const descripcion = `${handleMostrarCodigoArticulo(
      articulo,
      configGeneral,
    )} ${handleMostrarDescripcionArticulo(articulo)}`;

    return setDescripcionPersonalizada(descripcion);
  };

  useEffect(() => {
    handleSetDescripcionPersonalizada();
  }, [descripcionPersonalizada, detalles]);

  return (
    <TableRow key={index} index={index}>
      <TableCell
        style={{
          minWidth: 300,
          maxWidth: 300,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={articulo.descripcion.toUpperCase()}
      >
        {/* <span className={classes.fontSizeOptions}>
          {handleShowCodigo(articulo)}
          {articulo.descripcion.toUpperCase()}
        </span>

        {!isPresupuesto &&
        articulo.idRepuestoProveedor &&
        articulo.observaciones.length > 0 ? (
          <p style={{ fontSize: "0.8rem", opacity: "0.5" }}>
            {articulo.observaciones[0].substr(0, 40).trim() +
              (articulo.observaciones[0].length > 40 ? "..." : "")}
          </p>
        ) : null} */}
        {handleRenderDescripcion(articulo)}
      </TableCell>
      <TableCell align="center" style={{ minWidth: 250, maxWidth: 250 }}>
        <FormInput
          min="0"
          type="number"
          className="text-center"
          onKeyPress={handleKeyPress}
          onPaste={handlePaste}
          onFocus={(event) => event.target.select()}
          invalid={
            configGeneral.activar_validacion_precio && !articulo.precioValido
          }
          onChange={(event) =>
            dispatch(
              cambiarPrecio(detalles, index, event, descuento, medioPago),
            )
          }
          value={articulo.precio}
          name="precio"
          disabled={
            isVenta &&
            cliente &&
            cliente.clienteDescuento.isClienteDescuento &&
            medioPago[0] &&
            medioPago[0].nombre &&
            medioPago[0].nombre === "Cuenta Corriente"
              ? true
              : false || idOrden
          }
        />
        {configGeneral.activar_validacion_precio && (
          <FormFeedback>
            Mayor a $
            {articulo.precioCosto ||
              (articulo.precios && articulo.precios.costo) ||
              "0.00"}
          </FormFeedback>
        )}
      </TableCell>
      <TableCell align="center" style={{ minWidth: 130, maxWidth: 130 }}>
        <MaskedInput
          className="text-center form-control"
          guide={false}
          mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          onFocus={(event) => event.target.select()}
          disabled={idOrden ? true : false}
          onChange={(e) => {
            dispatch(cambiarCantidad(detalles, index, e, descuento, medioPago));
          }}
          value={articulo.cantidad}
          invalid={!articulo.cantidadValida}
          name="cantidad"
        />
        <FormFeedback>Cantidad inválida</FormFeedback>
      </TableCell>
      <TableCell align="center" style={{ minWidth: 130, maxWidth: 130 }}>
        {listaDePrecios.length > 0 && listaDePrecios[0].is_precio_lista ? (
          <>
            <FormSelect
              value={selectedListaDePrecios}
              onChange={changeListaPrecio}
              name="lista_precios"
              invalid={!selectedListaDePrecios}
            >
              <option value="">Seleccionar Lista de Precios ...</option>
              {listaDePrecios.map((lp) => {
                if (lp.is_precio_lista) {
                  return (
                    <option key={lp.id} value={lp.id}>
                      {lp.nombre}
                    </option>
                  );
                }
              })}
            </FormSelect>

            {/* Se muestra el msj si se decide confirmar la venta sin seleccionar una Lista de Precios */}
            {!selectedListaDePrecios && (
              <FormFeedback>
                Se debe seleccionar una Lista de Precios.
              </FormFeedback>
            )}
          </>
        ) : (
          "---"
        )}
      </TableCell>
      <TableCell
        align="center"
        title={articulo.subtotal.toLocaleString("es-AR")}
        style={{ minWidth: 170, maxWidth: 170 }}
      >
        <div
          className="d-flex fontBold"
          style={{ justifyContent: "center", fontSize: 18 }}
        >
          $
          {articulo.subtotal.toLocaleString("es-AR").length < 10
            ? articulo.subtotal.toLocaleString("es-AR")
            : articulo.subtotal.toLocaleString("es-AR").substr(0, 9) + "..."}
          {(articulo.margen || articulo.descuento) &&
          parseFloat(articulo.margen) !== 0 &&
          parseFloat(articulo.descuento) !== 0 ? (
            <TooltipMoreInfoMaterial
              titleTooltip={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  {`Aplica margen = ${
                    articulo.margen ? articulo.margen : 0.0
                  } y descuento = ${
                    articulo.descuento ? articulo.descuento : 0.0
                  }`}
                </h6>
              }
              position={"top"}
              color={"black"}
            />
          ) : null}
        </div>
      </TableCell>
      <TableCell
        align="center"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <i
          className="material-icons borrar-producto"
          style={{ margin: 0, cursor: "pointer", fontSize: 20 }}
          title="Eliminar"
          onClick={() =>
            dispatch(eliminarItem(detalles, index, descuento, medioPago))
          }
        >
          delete
        </i>
        {articulo.idRepuestoProveedor && !isPresupuesto && (
          <i
            className="material-icons borrar-producto"
            style={{
              margin: 0,
              cursor: "pointer",
              fontSize: 20,
              opacity:
                articulo.observaciones && articulo.observaciones.length
                  ? "1"
                  : "0.5",
            }}
            title="Eliminar"
            onClick={() => handleOpenObservaciones(articulo)}
          >
            visibility
          </i>
        )}
        {articulo.idRepuestoProveedor && (
          <i
            title="Agregar a pedido"
            onClick={() => handleOpenModalPedido(articulo)}
            className="material-icons"
            style={{ margin: "0 5px", cursor: "pointer", fontSize: 20 }}
          >
            local_shipping
          </i>
        )}
        <LocationIcon articulo={articulo} styles={{ fontSize: 20 }} />
      </TableCell>
    </TableRow>
  );
};

export default function BodyTable({ isVenta, idOrden }) {
  const classes = useStyles();

  const { detalles } = useSelector((store) => store.presupuesto);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [articuloModal, setArticuloModal] = useState(null);
  const [pedidosAbiertos, setPedidosAbiertos] = useState([]);
  const [openModalPedido, setOpenModlaPedido] = useState(false);
  const [modalObservacion, setModalObservacion] = useState(false);

  const handleSubmitObservaciones = async (obs, articulo) => {
    try {
      const response = await request({
        method: "PUT",
        url: observacionArticulo,
        data: {
          idRepuestoProveedor: articulo.idRepuestoProveedor,
          observaciones: obs,
        },
      });
      setModalObservacion(false);
      successNotification("Observaciones actualizadas con éxito");
    } catch (err) {
      console.log(err);
      errorNotification("Error, intente nuevamente");
    }
  };

  const handlePostPedido = async (articulo) => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: "POST",
        url: createPedidoDesdeRepuesto,
        data: {
          repuesto_proveedor_id: articulo.idRepuestoProveedor,
        },
      });
      setPedidosAbiertos(response.data.pedidos_abiertos);
      if (response.data.tiene_pedidos) {
        setOpenModlaPedido(true);
      } else {
        successNotification(
          `Se ha creado el pedido del artículo ${articulo.descripcion} con éxito`,
        );
      }
      setOpenBackdrop(false);
    } catch (error) {
      console.error(error);
      setOpenBackdrop(false);
    }
  };

  const handleOpenObservaciones = (articulo) => {
    setArticuloModal(articulo);
    setModalObservacion(true);
  };

  const handleOpenModalPedido = (articulo) => {
    handlePostPedido(articulo);
    setArticuloModal(articulo);
  };

  const handleCloseModalPedido = () => {
    setOpenModlaPedido(false);
    setArticuloModal(null);
  };

  return (
    <TableBody>
      {detalles.map((articulo, index) => (
        <ItemList
          articulo={articulo}
          index={index}
          isVenta={isVenta}
          idOrden={idOrden}
          handleOpenObservaciones={handleOpenObservaciones}
          handleOpenModalPedido={handleOpenModalPedido}
        />
      ))}

      {openBackdrop && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {modalObservacion && (
        <ModalObservacionarticulo
          open={modalObservacion}
          handleClose={() => {
            setModalObservacion(false);
            setArticuloModal(null);
          }}
          onSubmitObservaciones={(obs) =>
            handleSubmitObservaciones(obs, articuloModal)
          }
          articulo={articuloModal}
        />
      )}
      {openModalPedido && (
        <ModalPedido
          open={openModalPedido}
          close={handleCloseModalPedido}
          articuloSeleccionado={articuloModal}
          pedidosAbiertos={pedidosAbiertos}
        />
      )}
    </TableBody>
  );
}
