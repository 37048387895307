import React, { useState } from "react";
/**
 * MATERIAL-UI
 */
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createStyles, Grid, IconButton, makeStyles } from "@material-ui/core";
import BodyTable from "./BodyTable";
import FilterListIcon from "@material-ui/icons/FilterList";
import PopoverSelectItemsTable from "./PopoverSelectItemsTable";
import { useDispatch, useSelector } from "react-redux";
import { getConfigGeneral } from "../../../Redux/Actions/actionConfigGeneral";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapperTable: {
      height: (theme) => (theme.isVenta ? 100 : "calc(100vh - 380px)"),
      minHeight: 330,
      overflow: "auto",
    },
  }),
);

export default function ListaArticulosModificable({
  isVenta,
  idOrden,
  setDesabilitaRegistrarVenta,
  permisoPrecioCosto,
  permisoPrecioVenta,
}) {
  const classes = useStyles({ isVenta });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    configGeneral: {
      columnas_articulos_venta = [],
      columna_actualizacion_articulo = false,
    },
  } = useSelector((state) => state.configGeneral);
  const dispatch = useDispatch();
  const [listRowsSelected, setListRowsSelected] = useState(
    columnas_articulos_venta,
  );

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    dispatch(getConfigGeneral());
  }, []);

  React.useEffect(() => {
    let copyListRows = [...listRowsSelected];

    if (columna_actualizacion_articulo) {
      copyListRows.push(
        {
          id: 14,
          nombre: "Última actualización",
          es_visible: true,
        },
        {
          id: 15,
          nombre: "-",
          es_visible: true,
        },
      );
    } else {
      copyListRows.push({
        id: 14,
        nombre: "-",
        es_visible: true,
      });
    }

    setListRowsSelected(copyListRows);
  }, []);

  const ordenarListRows = (rows) => {
    const imageRow = rows.find((row) => row.nombre === "Imagen");
    if (imageRow) {
      return [imageRow, ...rows.filter((row) => row.nombre !== "Imagen")];
    }
    return rows;
  };

  return (
    <>
      <Grid container className="mt-1">
        <Grid container alignContent="center" alignItems="center">
          <Grid item xs={10}>
            <h5>Artículos</h5>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={2}>
            <IconButton color="primary" onClick={handleClickPopover}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={12} className="mt-1">
          <TableContainer className={classes.wrapperTable}>
            <Table
              stickyHeader
              size="small"
              aria-label=" a dense table"
              style={{ minWidth: "900px" }}
            >
              <TableHead>
                <TableRow>
                  {ordenarListRows(listRowsSelected)
                    .filter((x) => x.es_visible)
                    .map((header, idx) => (
                      <TableCell
                        key={idx}
                        align="center"
                        style={{
                          backgroundColor: "#007bff",
                          color: "#fff",
                          fontWeight: "bolder",
                        }}
                      >
                        {header.nombre}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <BodyTable
                isVenta={isVenta}
                idOrden={idOrden}
                listRowsSelected={listRowsSelected}
                columna_actualizacion_articulo={columna_actualizacion_articulo}
                setDesabilitaRegistrarVenta={setDesabilitaRegistrarVenta}
                permisoPrecioCosto={permisoPrecioCosto}
                permisoPrecioVenta={permisoPrecioVenta}
              />
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {anchorEl && (
        <PopoverSelectItemsTable
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          listRowsSelected={listRowsSelected}
          setListRowsSelected={setListRowsSelected}
        />
      )}
    </>
  );
}
